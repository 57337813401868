@import "~@laborhack/base-styles/lib/variables";

.heading {
  width: 100%;
  h2 {
    font-weight: 700;
    color: $dark-blue;
  }

  p {
    font-weight: 600;
    color: $dark-blue;
  }
}

.list {
  color: $dark-blue;
  margin: $margin-lg 0;
  padding: 0;
  width: 100%;

  li {
    list-style-type: none;
    @include flexbox();
    align-items: flex-start;
    margin: $margin-sm 0;

    i,
    p {
      font-size: $font-normal;
      line-height: 1.6;
    }

    i {
      color: $success-color;
      margin-right: $margin-sm;
    }
  }
}
