@import "~@laborhack/base-styles/lib/variables";

.wrapper {
  @include flexbox();
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: $margin-xl;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: $padding-sm;
  }

  h1 {
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: $margin-sm;
  }

  p {
    color: rgba($color: $dark-blue, $alpha: 0.6);
    margin-bottom: $margin-normal;

    a {
      color: $primary-color;
    }

    span {
      color: $success-color;
      font-weight: bold;
    }
  }
  .body {
    width: 100%;
    max-width: $grid-unit * 160;
    padding: $padding-xl;
    background-color: white;
  }
}

.submit {
  margin-top: $margin-normal;
}
