@import "../BaseStyles/variables.scss";

.layoutComponentBadge {
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  font-family: "Poppins";
  text-transform: capitalize;
  width: fit-content;
  &.progress {
    background-color: $progress-bg-color;
    color: $progress-text-color;
    &.inverted {
      color: $progress-text-color;
      background-color: rgba($color: $progress-text-color, $alpha: 0.2);
    }
  }
  &.success {
    background-color: $success-color;
    color: white;
    &.inverted {
      color: $success-color;
      background-color: rgba($color: $success-color, $alpha: 0.2);
    }
  }
  &.complete {
    background-color: #6bcb7a;
    color: white;
  }
  &.warning {
    background-color: $bright-red-color;
    color: $primary-color;
    &.inverted {
      color: $deep-red-color;
      background-color: rgba($color: $deep-red-color, $alpha: 0.2);
    }
  }
  &.default {
    background-color: $primary-color;
    color: white;
    &.inverted {
      background-color: rgba($color: $primary-color, $alpha: 0.37);
      color: $primary-color;
    }
  }

  &.basic {
    background-color: $dark-blue;
    color: white;
    &.inverted {
      background-color: white;
      color: $dark-blue;
      border: 1px solid rgba($color: $dark-blue, $alpha: 0.2);
    }
  }
  &.small {
    padding: $padding-xs 14px;
    border-radius: 5px;
    font-size: $font-xs;
    line-height: 10px;
  }
}
