@import "src/_components/BaseStyles/variables.scss";

.container {
  @include flexbox();
  justify-content: center;
  width: 100%;

  & > div {
    @include flexbox();
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding: $grid-unit * 24 $grid-unit * 6;
    max-width: 1280px;

    @media (max-width: $mobile) {
      padding: $grid-unit * 18 $grid-unit * 5;
    }
  }

  &.noPadding {
    & > div {
      padding: 0 $grid-unit * 5;
    }
  }
}
