@import "~@laborhack/base-styles/lib/variables.scss";

.input {
  margin: $margin-sm 0 !important;
  label {
    color: rgba($color: $dark-blue, $alpha: 0.7) !important;
    font-weight: 400 !important;
  }
}

.selectInput {
  width: 100%;
  min-height: 36px;
  background-color: transparent !important;
  border: 1px solid rgba($color: $backgroundTextColor, $alpha: 0.3) !important;

  a {
    border-radius: $grid-unit * 3 !important;
    height: $grid-unit * 6 !important;
    background-color: $backgroundTextColor !important;
    color: white !important;
  }
}
