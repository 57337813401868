// layout
$grid-unit: 4px;
$padding-xs: $grid-unit;
$padding-sm: $grid-unit * 2;
$padding-normal: $grid-unit * 4;
$padding-lg: $grid-unit * 8;
$padding-xl: $grid-unit * 16;

$margin-xs: $grid-unit;
$margin-sm: $grid-unit * 2;
$margin-normal: $grid-unit * 4;
$margin-lg: $grid-unit * 8;
$margin-xl: $grid-unit * 16;

$border-xs: $grid-unit;
$border-sm: $grid-unit * 2;
$border-normal: $grid-unit * 4;
$border-lg: $grid-unit * 8;
$border-xl: $grid-unit * 16;

// font-sizes
$font-xs: 0.71em;
$font-sm: 0.85em;
$font-normal: 1em;
$font-lg: 1.28em;
$font-xl: 1.71em;
$font-xxl: 2.57em;
$font-3xl: 3.57em;

// breakpoint
$tablet: 991px;
$tablet-sm: 768px;
$mobile: 425px;

// colors
$primary-color: #ed6e5c;
$light-primary-color: #fc806f;
$dark-blue: #003952;
$white: #ffffff;
$shadow-blue: #fbfeff;
$backgroundTextColor: #7c95a0;
$deep-grey: #595656;
$light-grey: #a4a4a4;
$inactive-text-color: #d0d0d0;
$light-primary: #ff7c6a;
$light-grey-badge-bg-color: #ededed;
$deep-blue-light-shade: #15495f;
$inactive-bg-color: #ebebeb;
$basic-bg-color: #dadada;
$success-color: #56bd66;
$light-primary: #ff7c6a;
$progress-bg-color: #f4e3b3;
$progress-text-color: #c79f29;
$bright-red-color: #ff080830;
$deep-red-color: #bb2c2c;
$light-bg-green: #6bcb79;
$light-bg-yellow: #f1c644;
$light-basic-text-color: #797979;
$transparent-primary: #ffeee9;
$notification-color: #f46956;
$disabled-btn-color: #686868;
$laborhack-red-light-shade: #fcd9d5;
$yellow: #ffcc03;
$blue: #009fe3;
$green: #28b3a4;
$orange: #f08604;

h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
span,
input,
textarea,
label,
p {
  font-family: "Poppins", sans-serif;
}

label {
  color: $dark-blue;
}

textarea,
input {
  font-weight: 600;
}

input::placeholder,
textarea::placeholder {
  font-weight: 400;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin linearGradient() {
  background: linear-gradient(95.19deg, #f08604 10.58%, #ed6d5c 54.57%);
}

@mixin card-style() {
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: white;
  margin: 0 0 $margin-sm 0;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}
