@import "~@laborhack/base-styles/lib/variables";

.loading {
  @include flexbox();
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: white, $alpha: 0.6);

  i {
    color: rgba($color: $primary-color, $alpha: 0.6);
  }
}
