@import "~@laborhack/base-styles/lib/variables";

.paymentProviderCard {
  @include flexbox();
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: $grid-unit * 64;
  padding: $padding-xl $padding-sm;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: $grid-unit * 2;
  border: 2px solid rgba($color: $dark-blue, $alpha: 0.4);
  margin: 0 $margin-normal $margin-normal 0;
  cursor: pointer;

  @media (max-width: $mobile) {
    max-width: none;
    flex-wrap: wrap;
    margin-right: 0;
    padding: $padding-lg $padding-sm;
  }

  .stickTopRight {
    position: absolute;
    top: $grid-unit * 3;
    right: $grid-unit * 3;
  }

  .checkIcon {
    display: none;
    width: 24px;
    height: 24px;
    fill: $success-color;
  }

  &.disabled {
    cursor: default;
    border-color: rgba($color: $dark-blue, $alpha: 0.1);

    .body {
      opacity: 0.2;
    }
  }

  &.selected {
    border: 4px solid $success-color;
    .checkIcon {
      display: block;
    }
  }

  .body {
    @include flexbox();
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .header {
      width: 100%;
      text-align: center;
      h3 {
        width: 100%;
        font-size: $font-normal;
        font-weight: bold;
        color: $dark-blue;
      }

      p {
        span {
          color: $primary-color;
        }

        font-weight: normal;
        color: $backgroundTextColor;
      }
    }
  }
}
