.o-contact__page {
  margin: 32px 0;
}


.contact__page-social {
  margin-top: 24px;
  h1 {
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 700;
  }
  .a-cart__accentBar {
    margin-bottom: 32px;
    height: 6px;
  }
}

.o-contact__form {
  button.ui.button {
    background: #ed6e5c;
    text-transform: uppercase;
    height: 42px;
  }
  button.ui.button:hover {
    background-color: #ed6e5cb3;
  }
}
.o-contact__page-social-icons {
  i.icon {
    font-size: 2em;
    color: #ed6e5c;
    cursor: pointer;
  }
}

@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }

  .o-contact__page-box {
    width: 100%;
  }
}
