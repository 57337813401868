@import "~@laborhack/base-styles/lib/variables";

.verify {
  width: 100%;
  max-width: $grid-unit * 120;
  padding: $padding-xl 0;
  h1 {
    font-weight: bold;
    color: $dark-blue;
  }

  p {
    color: rgba($color: $dark-blue, $alpha: 0.6);

    a {
      color: $primary-color;
    }

    span {
      color: $success-color;
      font-weight: bold;
    }
  }

  img {
    width: $grid-unit * 90;
  }

  section {
    margin: $margin-xl 0;
  }
}

.inlineButton {
  display: inline;
  height: fit-content;
}

.emailButton {
  background: transparent;
  padding: $padding-sm $padding-lg;
  border-radius: $grid-unit;
  border: 1px solid rgba($color: $dark-blue, $alpha: 0.15);

  svg {
    width: $grid-unit * 6;
    height: $grid-unit * 6;
  }

  p {
    margin-left: $margin-sm;
    color: $dark-blue;
    font-weight: 600;
  }
}
