.o-covid__page {
  margin-top: 12px;
}

.covid-hero__section {
  background-image: url("https://res.cloudinary.com/laborhack/image/upload/v1616418071/page%20assets/Homepage_Our-COVID-Response_sflk7y.svg");
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: center;
  height: 500px;
  box-shadow: inset 0 0 0 1000px #10182099;
  .hero-content {
    padding: 150px 20px 20px;
    color: #fff;
    h4 {
      color: #f46956;
      font-size: 28px;
      font-family: poppins;
      font-weight: 400;
      margin-bottom: 0;
    }
    h2 {
      margin-top: 1px;
      font-family: poppins;
      color: #fff;
      font-size: 40px;
    }
    p {
      font-size: 18px;
    }
  }
}
.response-header {
  text-align: center;
  text-align: center;
  padding: 16px;
  margin: 20px;
  h3 {
    font-family: poppins;
    font-size: 36px;
    margin-bottom: 0;
  }
  .a-cart__accentBar {
    margin: auto;
  }
}
.covid-response__section {
  margin: 40px 0 100px;
}
.response-card {
  background: #f4695638;
  border-radius: 4px;
  padding: 36px 12px;
  margin: 12px;
  display: flex;
  flex-wrap: wrap;
  .response-left-icon {
    width: 20%;
    .icon_box {
      width: 60px;
      height: 60px;
      background: #ed6e5c;
      margin: auto;
      img {
        width: 100%;
        padding: 4px;
      }
    }
  }
  .response-right-content {
    width: 75%;
    padding: 0 12px;
    h4 {
      font-family: poppins;
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }
}

// Mobile view

@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
  .response-card {
    margin: 12px 0;
    padding: 12px;
  }
  .response-header h3 {
    font-family: poppins;
    font-size: 26px;
    margin-bottom: 0;
  }
  .covid-hero__section .hero-content h2 {
    font-size: 28px;
  }
  .covid-hero__section .hero-content p {
    font-size: 16px;
  }
  .covid-hero__section .hero-content h4 {
    font-size: 24px;
  }
  .covid-hero__section .hero-content {
    padding: 150px 12px 20px;
  }
}
