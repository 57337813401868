$primary-color: #ff5f58;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;

$activeSideNavBgColor: #05acf3;

$backgroundTextColor: #7c95a0;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.o-register__wrapper {
  margin-top: 18px;

  .a-register__clickHere {
    cursor: pointer;
    color: #0095e3;
  }
}
.o-signup__page {
  .ui.grid > .row {
    padding-bottom: 0;
  }
  .ui.form .field .prompt.label {
    background: #f7cbcb !important;
  }
}
.o-signup__page-bg {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.o-signup__left {
  background-image: url("./../../../assets/maxim-selyuk2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 50%;
  height: 100vh;
}
.o-signup__page-cn {
  .ui.grid > .row {
    padding-bottom: 0;
    padding-top: 0;
  }
}
.o-signup__page-header-left {
  font-family: "Poppins";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 12px;
  transform: translate(-50%, -50%);
  color: #fff;
}
.o-signup__page-header-left h1 {
  font-family: "Poppins";
  color: #fff;
  font-size: 30px;
  text-transform: capitalize;
  margin: 0 0 20px 0;
}
.o-signup__page-col-right {
  display: flex;
  width: 100%;
  align-items: center;
}

.o-signup__page-col-right-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .o-signup__form-extras {
    margin: 20px 0 0 0;
  }

  .ui.form .error.message {
    display: block;
    padding: 8px;
    font-size: 12px;
    font-weight: bold;
    background: white;
  }

  .ui.form {
    width: 100%;
    padding-left: 10%;
  }
  .o-signup__header-wrapper {
    margin-bottom: 50px;
  }
  .a-cart__accentBar {
    height: 8px;
  }
  .o-signup__header-wrapper h1 {
    font-family: poppins;
    font-size: 30px;
    margin-bottom: 0;
  }
  button.ui.button {
    background: $primary-color;
    text-transform: uppercase;
    height: 42px;
  }
}
.o-signup__social-follow h1 {
  font-size: 22px;
  margin-bottom: 0;
  font-family: inherit;
}
.o-signup__social-follow {
  .a-cart__accentBar {
    height: 5px;
  }
  i.icon,
  i.icons {
    font-size: 2em;
    color: #ed6e5c;
  }
  .o-signup__page-social-icons {
    padding-top: 20px;
  }
}
.o-registerPassword__Info,
.o-registerPhonenumber__Info {
  background: #b7b7b721;
  padding: 5px;
  border-radius: 3px;
  margin-top: 6px;
}

@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
  .o-signup__page {
    background: none;
    box-shadow: none;
  }
  .o-signup__page-col-right-wrapper .a-cart__accentBar {
    height: 4px;
  }
  .o-signup__page-col-right-wrapper .o-signup__header-wrapper h1 {
    font-family: poppins;
    font-size: 26px;
    margin-bottom: 0;
  }
  .o-signup__page-col-right-wrapper .o-signup__header-wrapper {
    margin-bottom: 22px;
  }
  .o-signup__page-col-right-wrapper .ui.form {
    width: 100%;
    padding: 4px;
  }
  .o-signup__page-bg {
    display: none;
  }
}
/* Tablet / iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large screen only"]:not(.tablet),
  [class*="widescreen only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
  .o-signup__page {
    background: none;
    box-shadow: none;
  }
  .o-signup__page-bg {
    display: none;
  }
}

.o-emailVerified__wrapper {
  margin: 50px 0 0 0;
  @include flexbox();
  align-items: center;
  justify-content: center;
  width: 100%;

  .o-emailVerified__container {
    @include flexbox();
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 480px;
    flex-wrap: wrap;
    img {
      margin-bottom: 16px;
      width: 480px;
      height: 320px;
      @media (max-width: 576px) {
        width: 300px;
        height: 200px;
      }
    }

    h5 {
      width: 100%;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      color: $dark-blue;
      font-size: 18px;
      @media (max-width: 576px) {
        font-size: 14px;
      }
    }

    .o-emailVerified__actions {
      @include flexbox();
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      margin: 16px 0;
    }
  }
}

.m-signup__successModal {
  @include flexbox();
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  flex-wrap: wrap;
  text-align: center;
  margin: 36px 0;

  h4 {
    width: 100%;
    font-family: "Poppins", sans-serif;
    color: $dark-blue;
    font-weight: 800;
    font-size: 24px;
  }

  p {
    color: $backgroundTextColor;
    font-size: 14px;

    .a-signup__successModalLink {
      font-family: "Poppins", sans-serif;
      cursor: pointer;
      background: transparent;
      border: none;
      color: $primary-color;
      font-weight: 600;
      padding: 0;
      outline: none;

      &.-green {
        color: rgb(16, 163, 16);
      }

      &:hover {
        text-decoration: underline;

        &.-text {
          text-decoration: none;
          cursor: default;
        }
      }
    }
  }
}

.m-registrationForm__inputHint {
  margin: 5px 0 0 0;
  width: 100%;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
  }
}
