@import "~@laborhack/base-styles/lib/variables";

.login {
  width: 100%;
  max-width: $grid-unit * 120;
  padding: $padding-xl 0;
  h1 {
    font-weight: 700;
    color: $dark-blue;
    font-family: AeonikPro;
    font-size: 44px;
    line-height: 54px;
  }

  h2 {
    font-weight: 500;
    color: $light-grey;
    font-family: Inter;
    font-size: 18px;
  }

  p {
    color: rgba($color: $dark-blue, $alpha: 0.6);
    font-family: AeonikPro;

    a {
      color: $primary-color;
    }
  }

  .radioText {
    font-size: 18px;
    font-weight: 400;
    font-family: Inter;
  }
}
