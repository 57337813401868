@import "~@laborhack/base-styles/lib/variables";

.wrapper {
  @include flexbox();
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;

  img {
    width: 360px;

    @media (max-width: $mobile) {
      width: 240px;
    }
  }

  article {
    margin-top: $margin-lg;
    text-align: center;
    h1 {
      font-size: $font-xl;
      font-weight: bold;
      color: $dark-blue;
    }

    p {
      margin-top: $margin-normal;
      color: rgba($color: $dark-blue, $alpha: 0.6);
      max-width: $grid-unit * 160;
    }
  }

  a {
    color: $primary-color;
    text-decoration: underline;
  }

  .actions {
    margin-top: $margin-lg;

    .link {
      display: block;
      margin-bottom: $margin-normal;
    }
  }
}
