.ui.form .success.message {
  display: block;
}
h2.ui.header {
  font-family: poppins;
  font-size: 30px;
  margin-top: 24px;
}
.reset-card {
  max-width: 35%;
}
.form-text {
  text-align: left;
}
.reset-form__wrapper {
  background: #fff;
  box-shadow: 0px 0px 4px 1px rgba(34, 36, 38, 0.15);
  padding: 30px 60px;

  button.ui.button {
    text-transform: uppercase;
  }
  .form-title {
    margin-bottom: 36px;

    .a-cart__accentBar {
      margin: auto;
    }
    h3 {
      font-family: poppins;
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .form-text {
    font-family: poppins;
    font-size: 16px;
    margin-bottom: 16px;
    p {
      margin-bottom: 0;
      line-height: 24px;
    }
  }
  .form-field {
    .ui.success.message {
      background: #10ad10;
      color: #ffffff;
      font-size: 16px;
      text-align: -webkit-center;
    }
    .ui.icon.message > .icon:not(.close) {
      font-size: 28px;
    }
    .ui.fluid.input {
      height: 45px;
    }
    .ui.fluid.button {
      height: 45px;
    }
    button.ui.button:hover {
      background-color: #ed6e5cab;
      color: #ffffff;
    }
    .ui.fluid.input > input {
      font-family: poppins;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .ui.pointing.label,
  .ui[class*="pointing above"].label {
    white-space: normal;
    background: #fff !important;
    border: 1px solid #e0b4b4 !important;
    color: #9f3a38 !important;
  }
}

.success-text {
  text-align: center;
  img {
    margin-bottom: 12px;
  }
}
@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large screen only"]:not(.mobile),
  [class*="widescreen only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
  .reset-card {
    max-width: 100%;
    margin: 12px;
  }
  .reset-form__wrapper {
    margin: 12px;
    padding: 12px;
  }
}
