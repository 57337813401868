@import "~@laborhack/base-styles/lib/variables";

.wrapper {
  @include flexbox();
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;

  @media (max-width: $tablet) {
    justify-content: center;
    flex-wrap: wrap;
  }

  .column {
    background-color: white;
    order: 2;
    // make sure each column takes exactly half of the screen
    width: 50%;

    @media (max-width: $desktop) {
      // take the whole screen width when below tablet
      flex: auto;
      width: 100%;
    }

    &.authenticationPage {
      @include flexbox();
      flex-direction: column;
      width: 67%;
      align-items: center;
      height: 100vh;
      overflow-y: auto;

      @media (max-width: $tablet) {
        order: 1;
      }

      @media (min-width: $desktop) {
      }
    }
  }
}

.rightSideImage {
  background-image: url("../../assets/artisan-side-view.png");
  height: 100vh;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
}
