.SingleDatePicker {
  width: 100%;
}

.SingleDatePickerInput {
  width: 100%;
  border-radius: 4px;
}

.DateInput {
  width: 100%;
}

.DateInput_input {
  font-weight: 600;
  font-size: 1rem;
  height: 36px;
  border-radius: 8px;
  color: #003952;
  background-color: #fff;
}
